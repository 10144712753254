const baseFitmentFields = ['Make', 'Model', 'Year', 'Trim', 'BodyStyle'];
const extraFitmentFields = [];
const ignoreFields = ['Universal'];

const categorySelectionPageUrl = '/category-list/';
const brandSelectionPageUrl = '/brands/';

globalThis.Convermax.handleProductImageSlider = (id, image) => {
  const nextButton = window.document.querySelector(`[data-product-id="${id}"] .card-image-next`);
  const prevButton = window.document.querySelector(`[data-product-id="${id}"] .card-image-prev`);

  if (image === 'second') {
    prevButton.style.opacity = '1';
    nextButton.style.opacity = '0';
  } else {
    prevButton.style.opacity = '0';
    nextButton.style.opacity = '1';
  }
};

export default {
  platform: 'bigcommerce',
  defaultView: 'grid',
  SearchRequestDefaults: {
    pageSize: 16,
  },
  product: {
    noImageSrc: 'https://client.convermax.com/static/img/noimage_240.png',
  },
  facets: {
    rangedFacet: [{ fields: ['price'], name: 'priceFacet' }],
  },
  pagination: {
    centralRange: 1,
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    categorySelectionPages: [
      { pathname: categorySelectionPageUrl, field: 'category' },
      { pathname: brandSelectionPageUrl, field: 'brand_name' },
    ],
    categorySelectionPageRedirect: false,
    isVehicleSelectionIsolated: true,
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'SearchBox',
      location: {
        replace: '.navUser-action.navUser-action--quickSearch',
      },
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxDialogButton',
      location: {
        insertAfter: '.navUser-item--wishlist',
        class: 'cm_search-box-root__dialog-button-container',
        wrapper: 'li',
      },
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'VehicleSpeakerFacetPanel',
      type: 'FacetPanel',
      fields: ['VehicleSpeaker'],
      showAllAlways: true,
      disableCollapse: true,
    },
    {
      name: 'SearchHeader',
      sortSelectClass: 'form-select form-select--small',
      sortEntries: {
        'relevance': 'Best Match',
        'name': 'A to Z',
        'name:desc': 'Z to A',
        'price': 'Price: Ascending',
        'price:desc': 'Price: Descending',
      },
    },
    {
      name: 'SearchResult',
    },
    {
      name: 'HomeVehicleWidget',
      type: 'VehicleWidget',
      location: {
        selector: 'body.default .vehicle-fit-guide #cm-ymm',
        class: 'cm_vehicle-widget_container__home container',
      },
      columnBreakpoint: 670,
      template: 'fitmentSearch/homeVehicleWidget',
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'FacetCategoryTiles',
      type: 'FacetTiles',
      location: '#cm_collection-list',
      template: 'facetTiles/hierarchicalContainer',
    },
    {
      name: 'FacetBrandTiles',
      type: 'FacetTiles',
      location: 'body.brands .brandGrid',
      template: 'facetTiles/hierarchicalContainer',
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
      ignoreFields,
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
  ],
};
