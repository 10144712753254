//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-120:_4260,_2252,_1424,_1320,_5328,_4264,_456,_6824;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-120')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-120', "_4260,_2252,_1424,_1320,_5328,_4264,_456,_6824");
        }
      }catch (ex) {
        console.error(ex);
      }