//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-120:_2980,_3552,_1704,_6240,_8340,_6032,_8136,_2704;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-120')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-120', "_2980,_3552,_1704,_6240,_8340,_6032,_8136,_2704");
        }
      }catch (ex) {
        console.error(ex);
      }